// import logoGrohe from "../images/reference/grohe/logo.png"
import logoHannah from "../images/reference/hannah/logo.png"
// import logoSib from "../images/reference/study-in-brno/logo.svg"
import LogoFSI from "../images/reference/fsivut/logofsi.png"
import LogoHelcel from "../images/reference/helcel/logohelcel.png"
import LogoIFE from "../images/reference/ife/logoife.png"
import LogoKiwi from "../images/reference/kiwi/logo.png"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"

import BGKiwi from "../images/reference/bg-kiwi.png"
import BGPH from "../images/reference/bg-ph.png"
import BGFSI from "../images/reference/bg-fsi.png"
import BGIFE from "../images/reference/bg-ife.png"
import BGHelcel from "../images/reference/bg-helcel.png"
import BGHannah from "../images/reference/bg-hannah.png"

export const MARKETING_REFERENCES = [
  {
    src: BGKiwi,
    alt: "",
    logo: LogoKiwi,
    logoAlt: "logo společnosti kiwi.com",
    title: "Kiwi.com",
    link: "marketing/reference/kiwi",
  },
  {
    src: BGFSI,
    alt: "",
    logo: LogoFSI,
    logoAlt: "logo fakulty strojního inženýrství",
    title: "FSI VUT",
    link: "marketing/reference/fsivut",
  },
  {
    src: BGHelcel,
    alt: "",
    logo: LogoHelcel,
    logoAlt: "logo společnosti Helcel",
    title: "HELCEL",
    link: "marketing/reference/helcel",
  },
  {
    src: BGIFE,
    alt: "",
    logo: LogoIFE,
    logoAlt: "logo společnosti IFE",
    title: "IFE-CR",
    link: "marketing/reference/ife",
  },
  {
    src: BGHannah,
    alt: "",
    logo: logoHannah,
    logoAlt: "logo společnosti Hannah",
    title: "Hannah",
    link: "marketing/reference/hannah",
  },
  {
    src: BGPH,
    alt: "",
    logo: LogoPlaneta,
    logoAlt: "logo společnosti Planetaher.cz",
    title: "Planetaher.cz",
    link: "marketing/reference/planetaher",
  },
  // {
  //   src: "https://placekitten.com/300/400",
  //   alt: "",
  //   logo: logoGrohe,
  //   logoAlt: "logo společnosti Grohe",
  //   subTitle: " ",
  //   title: "Grohe",
  //   link: "marketing/reference/grohe",
  // },
  // {
  //   src: "https://placekitten.com/300/400",
  //   alt: "",
  //   logo: logoSib,
  //   logoAlt: "logo Study in Brno",
  //   subTitle: " ",
  //   title: "Study in Brno",
  //   link: "marketing/reference/study-in-brno",
  // },
]
