import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import {
  PaddingWrapper,
  Button,
  ButtonStyle,
  Cards,
  ContainerCustom,
  BannerStyle,
  Banner,
} from "@igloonet-web/shared-ui"

import { MARKETING_REFERENCES } from "../model/reference-marketing-references"
import { VYVOJ_REFERENCES } from "../model/reference-vyvoj-references"

const Reference: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Reference | igloonet</title>
        <meta
          name="description"
          content="Reference na zajímavé zakázky, které jsme realizovali pro naše klienty."
        />
      </Helmet>
      <Banner
        style={BannerStyle.Reference}
        orangeHeading="Reference"
        text="Připojte se k&nbsp;našim spokojeným zákazníkům. Jsou jich desítky&nbsp;-&nbsp;menší, střední i&nbsp;velcí. Pomáháme jim jak s&nbsp;prodejem zboží a&nbsp;služeb, tak s&nbsp;náborem zaměstnanců"
      />

      <ContainerCustom>
        <PaddingWrapper>
          {/* <ReferencesFull anotherReferences={MARKETING_REFERENCES} /> */}
          {/* <ReferencesFull /> */}
          <Cards heading="Online marketing" data={MARKETING_REFERENCES} />

          <div className="text-center">
            <Button to="/marketing/reference" style={ButtonStyle.Primary}>
              Všechny marketingové reference
            </Button>
          </div>
        </PaddingWrapper>

        <PaddingWrapper>
          {/* <ReferencesFull anotherReferences={VYVOJ_REFERENCES} /> */}
          <Cards heading="Vývoj webů" data={VYVOJ_REFERENCES} />

          <div className="text-center">
            <Button to="/vyvoj/reference" style={ButtonStyle.Primary}>
              Všechny vývojové reference
            </Button>
          </div>
        </PaddingWrapper>
      </ContainerCustom>
    </Layout>
  )
}

export default Reference
